<template>
	<div class="px-2 sm:px-10 py-4">
    <div class="flex items-center space-x-4">
      <Text color="black" weight="bold" size="xl" class="sm:text-3xl" :content="content.title"/>
      <Text size="sm" color="primary-600" content="edit" decoration="underline" custom-class="cursor-pointer"/>
    </div>
    <div class="flex justify-between items-center space-x-2 mt-2">
      <Text color="black" weight="semibold" size="sm" :content="content.description"/>
      <Text size="sm" color="primary-600" content="edit" decoration="underline" custom-class="cursor-pointer"/>
    </div>
    <div class="mt-10">
      <EmptyState v-if="isOpen" class="bg-white" isFull v-bind="content.empty" @onClose="() => isOpen = false"/>
    </div>
    <div class="mt-10">
      <div class="flex items-center space-x-5">
        <Text color="black" weight="bold" size="2xl" customClass="sm:text-2xl" :content="content.research.title"/>
        <Badge :label="content.research.badge" px="3" py="1" bg-color="primary-500" custom-class="rounded-full" text-color="white" text-weight="semibold"/>
      </div>
      <Text size="xs" color="black" :content="content.research.description" custom-class="mt-2"/>
      <Table v-if="content.research.items?.length > 0" :list="content.research.items" class="mt-5"/>
    </div>
    <div class="mt-10">
      <div class="flex items-center space-x-5">
        <Text color="black" weight="bold" size="2xl" customClass="sm:text-2xl" :content="content.opportunity.title"/>
      </div>
      <Text size="xs" color="black" :content="content.opportunity.description" custom-class="mt-2"/>
      <Table v-if="content.opportunity?.items?.length > 0" :list="content.opportunity.items" class="mt-5"/>
    </div>
    <div class="mt-10">
      <div class="flex items-center space-x-5">
        <Text color="black" weight="bold" size="2xl" customClass="sm:text-2xl" :content="content.venture.title"/>
        <Text size="sm" color="primary-600" :content="content.venture.btnText" decoration="underline" custom-class="cursor-pointer"/>
      </div>
      <Table v-if="content.venture?.members?.length > 0" :list="content.venture.members" class="mt-3 mb-5"/>
      <Text @click="isShow = true" size="sm" color="primary-600" :content="content.venture.inviteBtnText" decoration="underline" custom-class="cursor-pointer"/>
    </div>

    <div class="mt-10">
      <ListViewHeader title="Resources"/>
      <div class="space-y-3 mt-5">
        <CardWithImage v-for="idea in content.resources" :key="idea.id" v-bind="idea"/>
        <Text size="sm" color="primary-600" content="More Resources" decoration="underline" custom-class="cursor-pointer"/>
      </div>
    </div>
    <InviteMember :is-show="isShow" @onClose="isShow = false" @onSubmit="(formGroup) => onSubmitBtn(formGroup)" v-bind="{
			...c.inviteMember,
			isShow,
			isLoading,
			list: state.group.groups,
		}" />
	</div>
</template>

<script>
import EmptyState from '../components/organisms/EmptyState/Range/Range'
import ListViewHeader from '../components/molecules/ListViewHeader/WithTitleAndButton/WithTitleAndButton'
import CardWithBadge from "../components/molecules/CardListItem/CardWithBadge/CardWithBadge";
import CardWithImage from "../components/molecules/CardListItem/CardWithImage/CardWithImage";
import Table from '../components/organisms/Tables/Simple/Simple.vue'
import Text from '../components/atoms/Text/Text';
import { c } from '../components/constants.js';
import InviteMember from '../components/organisms/Modals/InviteMember/InviteMember.vue'
import { get } from 'lodash'
import { Settings } from "../../settings";

export default {
	components: {
		EmptyState, ListViewHeader, CardWithImage, CardWithBadge, Text, InviteMember, Table
	},
	data() {
		return {
			content: c.venture_team,
			isShow: false,
			isOpenTip: true,
			isLoading: false,
			get,
			c,
			venture: null,
      inviteLink: `https://${Settings.mainDomain}/#/register-user?v=${this.$route.params?.ventureId}`,
		}
	},
	methods: {
    async onSubmitBtn(item) {
      console.log('onSubmit ====', item);
      if (this.isLoading) return false;
      this.isLoading = true;
      if (item?.length > 0) {
        const { inviteContact } = await this.actions.user.inviteContact({
          contacts: [{ email: item[0].value }],
          userId: this.state.currentUser?.id,
          extraData: {
            ventureId: this.venture?.id,
            ventureName: this.venture?.name,
            groupId: item[1]?.value?.id,
            username: this.state.currentUser?.username,
            inviteLink: this.inviteLink,
            fullName: this.state.currentUser?.firstName + ' ' + this.state.currentUser?.lastName,
          }
        });
        if (inviteContact) {
          this.isShow = false;
          setTimeout(() => this.actions.alert.showSuccess({ message: 'You\'ve successfully sent your invitation', title: 'Invitation sent' }), 1000)
        }
      }
      this.isLoading = false;
    }
	},
	async created() {
    await this.actions.group.getGroups();
    console.log(this.state.group.groups, 'groups')
	}
}
</script>
